import React from "react";
import { createRoot } from "react-dom/client";
import MatchesAndChallengesSection from "../react_frontend/campaigns/components/matches_and_challenges/MatchesAndChallengesSection";

document.addEventListener("DOMContentLoaded", () => {
  const rootElement = document.getElementById("matchallenges");

  if (rootElement) {
    createRoot(rootElement).render(
      React.createElement(MatchesAndChallengesSection, {
        projectId: rootElement.dataset.projectId,
        tieredCampaignId: rootElement.dataset.tieredCampaignId || 0,
        sectionHeader: rootElement.dataset.sectionHeader,
        primaryColor: rootElement.dataset.primaryColor,
        showGive: rootElement.dataset.showGive === "true",
        allowChallenges: rootElement.dataset.allowChallenges === "true",
        allowMatches: rootElement.dataset.allowMatches === "true",
        userChallenge: rootElement.dataset.userChallenge === "true",
        userMatch: rootElement.dataset.userMatch === "true",
        offerChallengeUrl: rootElement.dataset.offerChallengeUrl,
        offerMatchUrl: rootElement.dataset.offerMatchUrl,
      })
    );
  }
});
